<template>
  <div id="sidebar-buttons">
    <b-button v-if="!isCompact" @click.prevent="resizeNavbar()" variant="outline-light">
      <font-awesome-icon icon="angle-double-left" size="lg" />
    </b-button>
    <!--
        <b-button v-if="!isCompact" @click="hideAll()">{{ $t('sidebar.hideall') }}</b-button>
    -->
  </div>
</template>

<script>
export default {
  props: {
    isCompact: { type: Boolean, default: false }
  },
  methods: {
    resizeNavbar() {
      this.$emit("resize");
    },
    showAll() {
      this.$emit("show", true);
    },
    hideAll() {
      this.$emit("show", false);
    }
  }
};
</script>

<style lang="scss" scoped>
#sidebar-buttons {
  margin: 0.8em;
  float: right;
}
</style>